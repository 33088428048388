import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import BlockFeatures from "../blocks/BlockFeatures";
import BlockProductColumns from "../blocks/BlockProductColumns";
import FaviconChanger from "../FaviconChanger/FaviconChanger";
import ProductsSection from "../../NewComponents/Products/ProductsSection";
import SlideShow from "../../NewComponents/Slider/SlideShow";
import PostsSection from "../../NewComponents/Posts/PostsSection";


function HomePageOne({ fetchedData }) {
    const [newProducts, setNewProducts] = useState([]);
    const [newMoreSales, setNewMoreSales] = useState([]);

    /**
     * Product columns.
     */
    const columns = [
        {
            title: "المنتجات الأكثر تقييماً",
            products: fetchedData?.data?.productsRatings?.slice(0, 3) || [],
        },
    ];

    useEffect(() => {
        setNewProducts(fetchedData?.data?.resentArrivede);
        setNewMoreSales(fetchedData?.data?.moreSales);
    }, [fetchedData?.data?.resentArrivede, fetchedData?.data?.moreSales]);

    return (
        <React.Fragment>
            <Helmet>
                <title>{`الرئيسية — ${localStorage.getItem("store-name")}`}</title>
            </Helmet>

            <SlideShow url="https://backend.atlbha.com/api/silders" />

            <BlockFeatures />

            {/* :: => Products Section */}
            <ProductsSection secTitle="الجديد" layout="grid-4" url="https://backend.atlbha.com/api/recentProducts" />

            {/* :: => Slide Show Banner */}
            <SlideShow url="https://backend.atlbha.com/api/banars" />

            {/* :: => Products Section */}
            <ProductsSection secTitle="المميزة" layout="grid-5" url="https://backend.atlbha.com/api/specialProducts" />

            {/* :: => Products Section */}
            <ProductsSection secTitle="الأكثر طلباً" layout="horizontal" url="https://backend.atlbha.com/api/moreSalesProducts" />

            {/* :: => Posts Section */}
            <PostsSection secTitle="المقالات" layout="list-sm" />

            {columns?.[0]?.products?.length !== 0 && <BlockProductColumns columns={columns} />}
            <FaviconChanger />
        </React.Fragment>
    );
}

export default HomePageOne;
